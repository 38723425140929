.create_marker_menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  z-index: 10000;
  background-color: white;
  gap: 15px;
  max-width: 400px;
  border: 1px solid #9d9d9d;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .type_select_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: start;

    .type_select {
      flex-grow: 1;
    }
  }

  .menu_element {
    background: none;
    border: 1px solid #9d9d9d;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
  }

  .close_button {
    align-self: flex-end;
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
  }

  .description_textarea {
    resize: none;
    cursor: text;
  }

  .components_slider {
    max-height: 140px;
  }
}

