.images_uploader {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .uploader_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upload_button {
    $color: rgba(#0d0f37, 1);
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    background: none;
    color: $color;
    border-radius: 5px;
    align-self: flex-end;

    .image_input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1000;
    }
  }
}
