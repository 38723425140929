.how_to_use_popup {
  background-color: white;
  border-radius: 20px;
  padding: 20px 20px 20px 40px;
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .close_button {
    background: none;
    border: none;
    cursor: pointer;
    align-self: flex-end;
  }
}