.loading-component {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  .loading-spinner {
    width: 50px;
    height: 50px;
    color: white;
    animation: spin 2s infinite linear;
  }
}

@keyframes spin {
  100% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}