$size: 40px;


.marker {
  cursor: pointer;
  width: $size;
  background-size: $size $size;
  transition: width 0.15s ease-in-out;

  .marker_image {
    width: 100%;
    height: 100%;
  }

  .marker_type {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    width: 60%;
  }

  &--active {
    $size: 60px;
    width: $size;
    background-size: $size $size;
    z-index: 1;
  }
}

