.app {
  display: flex;
  flex-direction: column;
  height: 100dvh;

  .main {
    position: relative;
    width: 100dvw;
    flex-grow: 1;
    @media (max-width: 1000px) {
      .marker_description {
        width: 50vw;
      }
    }
    @media (max-width: 600px) {
      .marker_description {
        width: 100vw;
      }
    }

    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      overflow: hidden;
    }

    $animation-speed: 0.5s;

    .map {
      height: 100%;
      width: 100%;
      transition: width $animation-speed ease;
    }

    .side_menu {
      transition: width $animation-speed ease;
    }
  }

  @media (min-width: 1024px) {
    .create_marker_menu {
      left: 0 !important;
      bottom: 0 !important;
      top: auto !important;
      transform: none !important;
      border-radius: 0 5px 0 0 !important;
    }
  }
}
