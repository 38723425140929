.marker_description {
  background-color: white;
  width: 30vw;
  overflow-x: hidden;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  align-items: center;
  z-index: 1000;

  &--showed {
    transform: translateX(0);
  }

  &--hidden {
    transform: translateX(100%);
  }

  .close_button {
    align-self: flex-start;
    cursor: pointer;
    background: none;
    border: none;
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    text-align: center;
  }
}


